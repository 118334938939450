export const plusInitialState = {
  adesoes: [],
  adesao: {},
  ciclos: [],
  potencias: [],
  tipoTarifas: [],
  escaloes: [],
  planos: [],
  status: [],
  services: [],
  adesaoFiles: [],
  adesaoEditada: {},
};
