import { plusInitialState } from "../initialState/plus";

const plusReducer = (state = plusInitialState, { type, payload }) => {
  switch (type) {
    case "GET_ALL_ADESOES":
      return {
        ...state,
        adesoes: payload,
      };
    case "GET_ADESAO_BY_ID":
      return {
        ...state,
        adesao: payload,
      };
    case "GET_PLUS_ESCALOES":
      return {
        ...state,
        escaloes: payload,
      };
    case "GET_PLUS_TIPO_TARIFAS":
      return {
        ...state,
        tipoTarifas: payload,
      };
    case "GET_PLUS_CICLOS":
      return {
        ...state,
        ciclos: payload,
      };
    case "GET_PLUS_POTENCIAS":
      return {
        ...state,
        potencias: payload,
      };
    case "GET_PLUS_PLANOS":
      return {
        ...state,
        planos: payload,
      };
    case "GET_PLUS_STATUS":
      return {
        ...state,
        status: payload,
      };
    case "GET_PLUS_SERVICES":
      return {
        ...state,
        services: payload,
      };
    case "GET_ADESAO_FILES_ADESAO_BY_ID":
      return {
        ...state,
        adesaoFiles: payload,
      };
    case "GET_ADESAO_FILES_COMP_EXC_BY_ID":
      return {
        ...state,
        adesaoFiles: payload,
      };
    case "EDIT_PLUS_ADESAO":
      return {
        ...state,
        adesaoEditada: payload,
      };

    default:
      return state;
  }
};

export default plusReducer;
