/* eslint-disable no-lone-blocks */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import {
  Typography,
  Box,
  IconButton,
  useTheme,
  Link,
  Breadcrumbs,
  Button,
  TextField,
  Autocomplete,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import "moment/locale/pt";
import MUIDataTable from "mui-datatables";
import Instance from "../../../utils/Axios";
import BASE_URL from "../../../utils/BASE_URL";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ptLocale from "date-fns/locale/pt";

// Components
import FooterLinks from "../../../Global/Components/Footer";

//Icons
import FeatherIcon from "feather-icons-react";
import GetAllAdesoes from "../../../utils/redux/actions/plus/GetAllAdesoes";
import { format } from "date-fns";
import SideDrawer from "./components/drawer";
import GetAdesaoById from "../../../utils/redux/actions/plus/GetAdesaoById";
import GetPlusTipoTarifas from "../../../utils/redux/actions/plus/GetPlusTipoTarifasAction";
import GetPlusCiclos from "../../../utils/redux/actions/plus/GetPlusCiclosAction";
import GetPlusEscaloes from "../../../utils/redux/actions/plus/GetPlusEscaloesAction";
import GetPlusPotencias from "../../../utils/redux/actions/plus/GetPlusPotenciasAction";
import GetPlusPlanos from "../../../utils/redux/actions/plus/GetPlusPlanosAction";
import fileDownload from "js-file-download";
import GetPlusStatus from "../../../utils/redux/actions/plus/GetPlusStatusAction";
import GetPlusServices from "../../../utils/redux/actions/plus/GetPlusServicesAction";
import GetAdesaoFilesById from "../../../utils/redux/actions/plus/GetAdesaoFilesAdesaoById";
import GetAdesaoFilesCompExcById from "../../../utils/redux/actions/plus/GetAdesaoFilesCompExcById";
import GetAdesaoFilesAdesaoById from "../../../utils/redux/actions/plus/GetAdesaoFilesAdesaoById";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      // margin: theme.spacing(1),
      width: "25ch",
    },
  },
  btnFiltrar: {
    color: "#E21450",
    width: "150px",
    height: "40px",
    fontWeight: "900",
    // borderRadius: '15px',
    backgroundColor: "white",
    border: `1px solid #707070`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#ECECEC",
      border: "1px solid #707070",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#F7F7F7 ",
      },
    },
  },
  stateLabel: {
    textTransform: "none",
    transition: "0.2s",
    display: "flex",
    alignItems: "center",
  },

  paperFilters: {
    background: "transparent",
    padding: "20px 0px",
    boxShadow: "none",
  },
  button: {
    display: "flex",
    justifyContent: "center",
    height: 40,
    borderTop: "4px solid transparent",
    borderBottom: "4px solid transparent",

    "&:hover": {
      borderRight: "4px solid #E21450",
    },
  },
}));

const ColorStateCube = ({ color }) => {
  return (
    <div
      style={{
        height: "10px",
        width: "10px",
        backgroundColor: color,
        marginRight: "10px",
        borderRadius: "3px",
      }}
    />
  );
};

// ############################################################## //
// ####################  Window Dimensions  ##################### //
// ############################################################## //

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const PlusAdesao = () => {
  const location = useLocation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [responsive] = useState("standard");
  const [tableBodyHeight] = useState("100%");
  const [tableBodyMaxHeight] = useState("");

  const adesoesState = useSelector((state) => state.plus.adesoes);

  const tiposTarifas = useSelector((state) => state.plus.tipoTarifas);
  const ciclos = useSelector((state) => state.plus.ciclos);
  const escaloes = useSelector((state) => state.plus.escaloes);
  const potencias = useSelector((state) => state.plus.potencias);
  const planos = useSelector((state) => state.plus.planos);
  const status = useSelector((state) => state.plus.status);

  const servicos = useSelector((state) => state.plus.services);

  const [servico, setServico] = useState({ id: null, name: "" });
  const [estado, setEstado] = useState({ id: null, name: "" });
  const [date, setDate] = useState(null);

  const filteredStatus = status.filter((s) => s.servId == servico.id);

  const handleDate = (date) => {
    setDate(date);
  };

  const fetchAdesoes = (service, status, date) => {
    dispatch(GetAllAdesoes(service, status, date));
  };

  const fetchAdesaoById = (id) => {
    dispatch(GetAdesaoById(id));
  };

  const fetchAdesaoFilesAdesaoById = (id) => {
    dispatch(GetAdesaoFilesAdesaoById(id));
  };

  const fetchAdesaoFilesCompExcById = (id) => {
    dispatch(GetAdesaoFilesCompExcById(id));
  };

  const fetchTipoTarifas = () => {
    dispatch(GetPlusTipoTarifas());
  };

  const fetchCiclos = () => {
    dispatch(GetPlusCiclos());
  };

  const fetchEscaloes = () => {
    dispatch(GetPlusEscaloes());
  };

  const fetchPotencias = () => {
    dispatch(GetPlusPotencias());
  };

  const fetchPlanos = () => {
    dispatch(GetPlusPlanos());
  };

  const fetchStatus = () => {
    dispatch(GetPlusStatus());
  };

  const fetchServices = () => {
    dispatch(GetPlusServices());
  };

  useEffect(() => {
    fetchCiclos();
    fetchEscaloes();
    fetchPotencias();
    fetchTipoTarifas();
    fetchPlanos();
    fetchStatus();
    fetchServices();
  }, []);

  useEffect(() => {
    const service = servico.id;
    const status = estado.id;
    const data = date;
    fetchAdesoes(service, status, data);
  }, []);

  const [adesao, setAdesao] = useState({
    nome: "",
    apelido: "",
    telemovel: "",
    email: "",
    nif: "",
    numero_documento: "",
    validade_documento: "",
    certidao_permanente: "",
    tarifaSocial: "",
    titular_contrato_anterior: "",
    preferencia_contacto: "",
    iban: "",
    morada: "",
    cod_postal: "",
    localidade: "",
    nova_ligacao: "",
    fornecimento_energia: "",
    tipotarifa: "",
    potencia: "",
    ciclo: "",
    escalao: "",
    aceito_condicoes_gerais: "",
    observacoes: "",
    data: "",
    nipc: "",
    cae: "",
    cpe: "",
    cui: "",
    nome_empresa: "",
    rep_legal: "",
    inspecao_gas: "",
    fornecimento_gas: "",
    fatura_gas: "",
  });

  const resetAdesao = () => {
    setAdesao({
      id: "",
      nome: "",
      apelido: "",
      telemovel: "",
      email: "",
      nif: "",
      numero_documento: "",
      validade_documento: "",
      certidao_permanente: "",
      iban: "",
      titular_contrato_anterior: "",
      preferencia_contacto: "",
      morada: "",
      cod_postal: "",
      localidade: "",
      concelho: "",
      distrito: "",
      nome_empresa: "",
      nipc: "",
      cae: "",
      rep_legal: "",
      cui: "",
      cpe: "",
      aceito_condicoes_gerais: "",
      observacoes: "",
      n_registo: "",
      potencia_resp: "",
      cpe_consumo: "",
      cpe_producao: "",
      morada_upac: "",
      cod_postal_upac: "",
      localidade_upac: "",
      concelho_upac: "",
      distrito_upac: "",
    });
  };

  // ############################################################## //
  // ########################  Drawer  ############################ //
  // ############################################################## //

  const [drawerWidth, setDrawerWidth] = useState("90vw");
  const dimensions = useWindowDimensions();
  const [state, setState] = useState({
    right: false,
  });
  const [drawerState, setDrawerState] = useState(null);
  const toggleDrawer = (anchor, open, id, idClient, idService) => {
    console.log(id);
    if (open == false) {
      setState({ ...state, [anchor]: open });
      // setCredenciacaoId(null);
      resetAdesao();
    }
    if (open == true) {
      setState({ ...state, [anchor]: open });
      // setCredenciacaoId(id);
      fetchAdesaoById(id);
      if (idService == 1) {
        fetchAdesaoFilesAdesaoById(idClient);
      } else {
        fetchAdesaoFilesCompExcById(idClient);
      }
    }
  };

  // ############################################################## //
  // ##################  MUI Datatable columns  ################### //
  // ############################################################## //

  const renderTarifa = (value) => {
    switch (value) {
      case "simples":
        return "Simples";
      case "bi_horario":
        return "Bi-Horário";
      case "tri_horario":
        return "Tri-Horário";
      default:
        break;
    }
  };

  const renderEscalao = (value) => {
    switch (value) {
      case "escalao1":
        return "Escalão 1";
      case "escalao2":
        return "Escalão 2";
      case "escalao3":
        return "Escalão 3";
      default:
        break;
    }
  };

  const columns = [
    {
      name: "id_jaf_plus_client_data",
      label: "",
      options: {
        display: false,
      },
    },
    {
      name: "id_jaf_plus_client",
      label: "",
      options: {
        display: false,
      },
    },
    {
      name: "jaf_plus_client_data_first_name",
      label: "Nome",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          const apelido = tableMeta.rowData[3];
          const empresa = tableMeta.rowData[4];
          return (
            <div className="table-btns-tiposatrasos">
              {value ? (
                <Typography>{`${value} ${apelido}`}</Typography>
              ) : (
                <Typography>{empresa}</Typography>
              )}
            </div>
          );
        },
      },
    },
    {
      name: "jaf_plus_client_data_last_name",
      label: "Apelido",
      options: {
        display: false,
      },
    },
    {
      name: "jaf_plus_client_data_company_name",
      label: "Nome de empresa",
      options: {
        display: false,
      },
    },
    {
      name: "jaf_plus_client_data_contact",
      label: "Telemóvel",
      options: {
        filter: true,
        sort: true,
      },
    },
    // {
    //   name: "jaf_plus_client_data_nif",
    //   label: "NIF",
    //   options: {
    //     filter: true,
    //     sort: true,
    //   },
    // },
    // {
    //   name: "jaf_plus_client_data_cpe",
    //   label: "CPE",
    //   options: {
    //     filter: true,
    //     sort: true,
    //     customBodyRender: (value, tableMeta) => {
    //       return (
    //         <div className="table-btns-tiposatrasos">
    //           {value == null ? (
    //             <Typography>N/D</Typography>
    //           ) : (
    //             <Typography>{value}</Typography>
    //           )}
    //         </div>
    //       );
    //     },
    //   },
    // },
    // {
    //   name: "jaf_plus_client_data_cui",
    //   label: "CUI",
    //   options: {
    //     filter: true,
    //     sort: true,
    //     customBodyRender: (value, tableMeta) => {
    //       return (
    //         <div className="table-btns-tiposatrasos">
    //           {value == null ? (
    //             <Typography>N/D</Typography>
    //           ) : (
    //             <Typography>{value}</Typography>
    //           )}
    //         </div>
    //       );
    //     },
    //   },
    // },
    {
      name: "id_jaf_plus_service_type",
      label: "Tipo de Contrato",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <div className="table-btns-tiposatrasos">
              {value == 1 ? (
                <Typography>Adesão</Typography>
              ) : value == 2 ? (
                <Typography>Compra de Excedentes</Typography>
              ) : (
                <Typography>N/D</Typography>
              )}
            </div>
          );
        },
      },
    },
    // {
    //   name: "id_jaf_plus_tariff_plan",
    //   label: "Tipo de Contrato",

    // },

    // {
    //   name: "power",
    //   label: "Potência",
    //   options: {
    //     filter: true,
    //     sort: true,
    //   },
    // },
    // {
    //   name: "cycle",
    //   label: "Ciclo",
    //   options: {
    //     filter: true,
    //     sort: true,
    //   },
    // },
    // {
    //   name: "level",
    //   label: "Escalão",
    //   options: {
    //     filter: true,
    //     sort: true,
    //     customBodyRender: (value, tableMeta) => {
    //       return (
    //         <div className="table-btns-tiposatrasos">
    //           <Typography>{renderEscalao(value)}</Typography>
    //         </div>
    //       );
    //     },
    //   },
    // },
    {
      name: "jaf_plus_client_created_at",
      label: "Data",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <div className="table-btns-tiposatrasos">
              {value == null ? (
                " - "
              ) : (
                <>{format(new Date(value), "dd/MM/yyyy")}</>
              )}
            </div>
          );
        },
      },
    },
    {
      name: "estadoNome",
      label: "Estado",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellProps: () => ({
          style: { width: "5%" },
          align: "center",
          margin: 0,
          padding: 0,
        }),
        customBodyRender: (value, tableMeta) => {
          const id = tableMeta.rowData[0];
          const idClient = tableMeta.rowData[1];
          const idService = tableMeta.rowData[6];
          const name = tableMeta.rowData[2];
          const contId = tableMeta.rowData[10];
          const contrato = tableMeta.rowData[11];
          return (
            <div style={{ display: "flex", float: "right" }}>
              {contrato > 0 ? (
                <IconButton
                  style={{
                    padding: 6,
                    color: theme.palette.secondary.main,
                  }}
                  onClick={() => {
                    Instance()
                      .get(
                        `${BASE_URL}/plus/adesoes/downloadContrato/${contId}`,
                        { responseType: "blob" }
                      )
                      .then(({ data }) => {
                        console.log(data);
                        const filename = "contrato_" + name + ".pdf";
                        fileDownload(data, filename);
                      })
                      .catch((err) => {
                        console.log(err.response);
                        if (err.response) {
                          if (err.response === 403) {
                            // dispatch(ShowNotification(errorMessage));
                          }
                        }
                      });
                  }}
                >
                  <FeatherIcon
                    icon="download"
                    size={20}
                    style={{ strokeWidth: "1.5" }}
                  />
                </IconButton>
              ) : null}
              <IconButton
                onClick={() => {
                  setDrawerState("watch");
                  toggleDrawer("right", true, id, idClient, idService);
                }}
                style={{
                  padding: 6,
                  color: theme.palette.secondary.main,
                }}
              >
                <FeatherIcon
                  icon="eye"
                  size={20}
                  style={{ strokeWidth: "1.5" }}
                />
              </IconButton>
            </div>
          );
        },
      },
    },
    {
      name: "contratoFileId",
      label: "contratoFileId",
      options: {
        display: false,
      },
    },
    {
      name: "contrato",
      label: "contrato",
      options: {
        display: false,
      },
    },
  ];

  // ############################################################## //
  // ##################  MUI Datatable Options  ################### //
  // ############################################################## //

  const searchText =
    location.state && location.state.funcionarioNome
      ? location.state.funcionarioNome
      : null;

  const options = {
    filter: false,
    filterType: "dropdown",
    responsive,
    searchText: searchText,
    tableBodyHeight,
    tableBodyMaxHeight,
    selectableRows: "none",
    viewColumns: false,
    elevation: 5,
    pagination: true,
    print: false,
    download: false,
    rowsPerPage: 100,
    rowsPerPageOptions: [100, 150, 200, 250, 300],
    textLabels: {
      body: {
        noMatch: "Sem Registos encontrados",
        // toolTip: 'Dia',
        columnHeaderTooltip: (column) => `${column.label}`,
      },
      toolbar: {
        search: "Pesquisar",
      },
      pagination: {
        next: "Proxima Página",
        previous: "Página Anterior",
        rowsPerPage: "Mostrar:",
        displayRows: "de",
      },
    },
  };

  const handleSubmit = () => {
    const serviceId = servico.id;
    const statusId = estado.id;

    fetchAdesoes(serviceId, statusId, date);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mr: 10,
          width: "100%",
        }}
      >
        <SideDrawer
          state={state}
          toggleDrawer={toggleDrawer}
          drawerState={drawerState}
          drawerWidth={drawerWidth}
          dimensions={dimensions}
          fetchAdesoes={fetchAdesoes}
          adesao={adesao}
          setAdesao={setAdesao}
          ciclos={ciclos}
          escaloes={escaloes}
          potencias={potencias}
          tiposTarifas={tiposTarifas}
          planos={planos}
          status={status}
          servico={servico}
          estado={estado}
          date={date}
        />
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              mx: 5,
              mt: 5,
              width: "100%",
            }}
          >
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" href="/construcoes/obras">
                Gestão Comercial
              </Link>
              <Typography color="text.primary">
                Contratos de Adesão / Compra de Excedentes
              </Typography>
            </Breadcrumbs>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}
            >
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box sx={{ width: "240px" }}>
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    Tipo de contrato
                  </Typography>
                  <Autocomplete
                    fullWidth
                    name="nome"
                    clearText="Limpar"
                    openText="Abrir"
                    closeText="Fechar"
                    noOptionsText="Sem dados"
                    value={servico}
                    className="unidadesId-form"
                    options={servicos || []}
                    getOptionLabel={(option) => `${option.name}` || ""}
                    isOptionEqualToValue={(option, value) => {
                      // if (!value.id) return true;
                      return value.id === option.id;
                    }}
                    style={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField {...params} size="small" variant="outlined" />
                    )}
                    onChange={(_, value, reason) => {
                      if (reason == "clear") {
                        setServico({ id: null, name: "" });
                      } else {
                        setServico(value);
                      }
                      return value;
                    }}
                  />
                </Box>
                <Box sx={{ width: "240px", mx: 4 }}>
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    Estado
                  </Typography>
                  <Autocomplete
                    fullWidth
                    name="nome"
                    clearText="Limpar"
                    openText="Abrir"
                    closeText="Fechar"
                    noOptionsText="Sem dados"
                    value={estado}
                    className="unidadesId-form"
                    options={filteredStatus}
                    getOptionLabel={(option) => `${option.name}` || ""}
                    isOptionEqualToValue={(option, value) => {
                      // if (!value.id) return true;
                      return value.id === option.id;
                    }}
                    style={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField {...params} size="small" variant="outlined" />
                    )}
                    onChange={(_, value, reason) => {
                      if (reason == "clear") {
                        setEstado({ id: null, name: "" });
                      } else {
                        setEstado(value);
                      }
                      return value;
                    }}
                  />
                </Box>
                <Box sx={{ width: "240px" }}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={ptLocale}
                  >
                    <Typography
                      style={{ fontWeight: "bold", marginBottom: "10px" }}
                    >
                      Data
                    </Typography>
                    <Box sx={{ display: "flex" }}>
                      <DatePicker
                        margin="normal"
                        id="date-picker-dialog"
                        format="dd/MM/yyyy"
                        okLabel="ok"
                        cancelLabel="cancelar"
                        invalidDateMessage="Data Inválida"
                        value={date}
                        onChange={handleDate}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "dd/mm/aaaa",
                            }}
                            InputProps={{
                              ...params.InputProps,
                              disableUnderline: true,
                            }}
                            sx={{ width: "200px" }}
                            variant="outlined"
                            size="small"
                          />
                        )}
                      />
                    </Box>
                  </LocalizationProvider>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignSelf: "end",
                }}
              >
                <Button
                  onClick={handleSubmit}
                  variant="contained"
                  className={classes.btnFiltrar}
                >
                  Filtrar
                </Button>
              </Box>
            </Box>
          </Box>

          <div
            style={{
              margin: "0px 40px 40px 40px",
              width: "100%",
            }}
          >
            <MUIDataTable
              title={
                <span
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                  className="text-underline"
                >
                  Contratos de Adesão / Compra de Excedentes
                </span>
              }
              data={adesoesState || []}
              columns={columns}
              options={options}
            />
          </div>
          <div style={{ margin: "20px 40px" }}>
            <FooterLinks />
          </div>
        </Box>
      </Box>
    </>
  );
};

export default PlusAdesao;
