/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";

// import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  Collapse,
  IconButton,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import FeatherIcon from "feather-icons-react";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ptLocale from "date-fns/locale/pt";
import moment from "moment";
import EditPlusAdesao from "../../../../utils/redux/actions/plus/EditPlusAdesaoAction";
import ShowNotification from "../../../../utils/redux/actions/global/notifications/NotificationAction";

const useStyles = makeStyles({
  list: {
    width: "80vw",
    borderRadius: "10px 0px 0px 10px !important",
    // background: 'red !important',
  },
  fullList: {
    width: "auto",
  },
  paper: {
    background: "#FAFAFA",
  },
  btnGuardar: {
    color: "white",
    width: "120px",
    height: "40px",
    // borderRadius: '15px',
    backgroundColor: "#E21450",
    border: `1px solid #E21450`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#bc1041",
      border: "1px solid #bc1041",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#bc1041",
      },
      "&:disabled": {
        backgroundColor: "#bc1041",
      },
    },
  },
  btnCloseDrawer: {
    color: "#464255",
    width: "30px",
    height: "40px",
    borderRadius: "15px",
    backgroundColor: "#f2f2f2",
    border: `1px solid #f2f2f2`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#e0e0e0",
      border: "1px solid #e0e0e0",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#e0e0e0",
      },
    },
  },
  drawerHeader: {
    display: "flex",
    justifyContent: "space-between",
  },

  noBorder: {
    border: "none",
  },
  btnFiltrar: {
    color: "#E21450",
    width: "115px",
    height: "40px",
    fontWeight: "bold",
    // borderRadius: '15px',
    backgroundColor: "white",
    border: `1px solid #707070`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#ECECEC",
      border: "1px solid #707070",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#F7F7F7 ",
      },
    },
  },
  btnTrue: {
    display: "inline-block",
    width: "100%",
    height: 40,
    border: "1px solid lightgrey",
    borderRadius: "5px",
    background: "white",
    "&:focus": {
      background: "white",
    },
    "&:hover": {
      backgroundColor: "lightgrey",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "lightgrey",
      },
    },
  },
  btnFalse: {
    display: "inline-block",
    width: "100%",
    height: 40,
    border: "1px solid lightgrey",
    borderRadius: "5px",
    background: "lightgrey",
    "&:focus": {
      background: "lightgrey",
    },
    "&:hover": {
      backgroundColor: "lightgrey",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "lightgrey",
      },
    },
  },
  btnUpload: {
    color: "#E21450",
    // width: '125px',
    height: "40px",
    fontWeight: "bold",
    // borderRadius: '15px',
    backgroundColor: "white",
    border: `1px solid #707070`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#ECECEC",
      border: "1px solid #707070",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#F7F7F7 ",
      },
    },
  },
  blurredText: {
    color: "transparent", // Make the text transparent
    textShadow: "0 0 10px #000", // Apply a text shadow to create the blur effect
    userSelect: "none", // Prevent text selection
  },
  disabledTextField: {
    opacity: 0.7,
    pointerEvents: "none",
    userSelect: "none", // Adjust opacity to indicate the disabled state
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "transparent",
    },
  },
  textField: {
    userSelect: "none",
  },
});

const Form = ({
  toggleDrawer,
  drawerWidth,
  dimensions,
  fetchAdesoes,
  adesao,
  setAdesao,
  ciclos,
  escaloes,
  potencias,
  tiposTarifas,
  planos,
  status,
  servico,
  estado,
  date,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [adesaoData, setAdesaoData] = useState();
  const [adesaoLastUpdateData, setAdesaoLastUpdateData] = useState();
  const [adesaoValiDoc, setAdesaoValiDoc] = useState();

  const documentos = useSelector((state) => state.plus.adesaoFiles);

  // ############################################################## //
  // ####################  Adesão Selecionada  #################### //
  // ############################################################## //

  // const prefContactos = useSelector((state) => state.plus.contactos);

  const [prefContacto, setPrefContacto] = useState({ id: 0, name: "" });
  const [tarifa, setTarifa] = useState({ id: 0, name: "" });
  const [ciclo, setCiclo] = useState({ id: 0, name: "" });
  const [escalao, setEscalao] = useState({ id: 0, name: "" });
  const [potencia, setPotencia] = useState({ id: 0, name: "" });
  const [tarifPlan, setTarifPlan] = useState({ id: 0, name: "" });
  const [estadoAde, setEstadoAde] = useState({ id: 0, name: "" });

  const [titCon, setTitCont] = useState({ id: 0, name: "" });
  const [newCon, setNewCon] = useState({ id: 0, name: "" });
  const [fornEne, setFornEne] = useState({ id: 0, name: "" });
  const [inspGas, setInspGas] = useState({ id: 0, name: "" });
  const [fornGas, setFornGas] = useState({ id: 0, name: "" });
  const [taxMorada, setTaxMorada] = useState({ id: 0, name: "" });

  const prefContactos = [
    { id: 1, name: "Email" },
    { id: 2, name: "Telemóvel" },
    { id: 3, name: "Correio" },
  ];

  const YesNo = [
    { id: 0, name: "Não" },
    { id: 1, name: "Sim" },
  ];

  const renderTarifa = (value) => {
    switch (value) {
      case "simples":
        return "Simples";
      case "bi_horario":
        return "Bi-Horário";
      case "tri_horario":
        return "Tri-Horário";
      default:
        break;
    }
  };

  const renderEscalao = (value) => {
    switch (value) {
      case "escalao1":
        return "Escalão 1";
      case "escalao2":
        return "Escalão 2";
      case "escalao3":
        return "Escalão 3";
      default:
        break;
    }
  };

  const renderPrefContacto = (email_flag, phone_flag, mail_flag) => {
    if (email_flag == "1") {
      setPrefContacto({ id: 1, name: "Email" });
    } else if (phone_flag == "1") {
      setPrefContacto({ id: 2, name: "Telemóvel" });
    } else if (mail_flag == "1") {
      setPrefContacto({ id: 3, name: "Correio" });
    }
    console.log(prefContacto);
  };

  const adesaoSelecionada = useSelector(
    (state) => state && state.plus && state.plus.adesao
  );

  const [personalInfo, setPersonalInfo] = useState({
    nome: "",
    apelido: "",
    telemovel: "",
    email: "",
  });

  const [docsInfo, setDocsInfo] = useState({
    nif: "",
    numero_documento: "",
    certidao_permanente: "",
    iban: "",
  });

  const [addressInfo, setAddressInfo] = useState({
    morada: "",
    cod_postal: "",
    localidade: "",
    concelho: "",
    distrito: "",
  });

  const [companyInfo, setCompanyInfo] = useState({
    nome_empresa: "",
    nipc: "",
    cae: "",
    rep_legal: "",
  });

  const [otherInfo, setOtherInfo] = useState({
    cui: "",
    cpe: "",
    aceito_condicoes_gerais: "",
    observacoes: "",
  });

  const [upacInfo, setUpacInfo] = useState({
    n_registo: "",
    potencia_resp: "",
    cpe_consumo: "",
    cpe_producao: "",
  });

  const [upacAddressInfo, setupacAddressInfo] = useState({
    morada_upac: "",
    cod_postal_upac: "",
    localidade_upac: "",
    concelho_upac: "",
    distrito_upac: "",
  });

  useEffect(() => {
    if (adesaoSelecionada) {
      setPersonalInfo({
        nome: adesaoSelecionada.jaf_plus_client_data_first_name,
        apelido: adesaoSelecionada.jaf_plus_client_data_last_name,
        telemovel: adesaoSelecionada.jaf_plus_client_data_contact,
        email: adesaoSelecionada.jaf_plus_client_data_email,
      });
      setDocsInfo({
        nif: adesaoSelecionada.jaf_plus_client_data_nif,
        numero_documento: adesaoSelecionada.jaf_plus_client_data_cc,
        certidao_permanente:
          adesaoSelecionada.jaf_plus_client_data_p_certificate_code,
        iban: adesaoSelecionada.jaf_plus_client_data_iban,
      });
      setAddressInfo({
        morada: adesaoSelecionada.jaf_plus_client_data_address,
        cod_postal: adesaoSelecionada.jaf_plus_client_data_postal_code,
        localidade: adesaoSelecionada.jaf_plus_client_data_location,
        concelho: adesaoSelecionada.jaf_plus_client_data_county,
        distrito: adesaoSelecionada.jaf_plus_client_data_district,
      });
      setCompanyInfo({
        nipc: adesaoSelecionada.jaf_plus_client_data_nipc,
        cae: adesaoSelecionada.jaf_plus_client_data_cae,
        nome_empresa: adesaoSelecionada.jaf_plus_client_data_company_name,
        rep_legal: adesaoSelecionada.jaf_plus_client_data_legal_name,
      });
      setOtherInfo({
        aceito_condicoes_gerais:
          adesaoSelecionada.jaf_plus_client_data_terms_conditions_flag,
        observacoes: adesaoSelecionada.jaf_plus_client_data_obs,
        cpe: adesaoSelecionada.jaf_plus_client_data_cpe,
        cui: adesaoSelecionada.jaf_plus_client_data_cui,
      });
      setUpacInfo({
        n_registo: adesaoSelecionada.jaf_plus_upac_regist_number,
        potencia_resp: adesaoSelecionada.jaf_plus_upac_power_resp,
        cpe_consumo: adesaoSelecionada.jaf_plus_upac_cep_consumption,
        cpe_producao: adesaoSelecionada.jaf_plus_upac_cep_production,
      });
      setupacAddressInfo({
        morada_upac: adesaoSelecionada.jaf_plus_upac_address,
        cod_postal_upac: adesaoSelecionada.jaf_plus_upac_postal_code,
        localidade_upac: adesaoSelecionada.jaf_plus_upac_location,
        concelho_upac: adesaoSelecionada.jaf_plus_upac_county,
        distrito_upac: adesaoSelecionada.jaf_plus_upac_district,
      });
      setTaxMorada({
        id: parseInt(adesaoSelecionada.jaf_plus_client_data_tax_address_flag),
        name:
          adesaoSelecionada.jaf_plus_client_data_tax_address_flag == "0"
            ? "Não"
            : "Sim",
      });
      renderPrefContacto(
        adesaoSelecionada.jaf_plus_client_data_contact_email_pref_flag,
        adesaoSelecionada.jaf_plus_client_data_contact_phone_pref_flag,
        adesaoSelecionada.jaf_plus_client_data_contact_mail_flag
      );
      setAdesaoData(
        moment(adesaoSelecionada.jaf_plus_client_created_at).format(
          "DD-MM-YYYY HH:mm:ss"
        )
      );
      setAdesaoLastUpdateData(
        moment(
          adesaoSelecionada.jaf_plus_client_updated_at
            ? adesaoSelecionada.jaf_plus_client_updated_at
            : adesaoSelecionada.jaf_plus_client_created_at
        ).format("DD-MM-YYYY HH:mm:ss")
      );
      setAdesaoValiDoc(
        adesaoSelecionada.jaf_plus_client_data_cc_due_date == "0000-00-00"
          ? null
          : new Date(adesaoSelecionada.jaf_plus_client_data_cc_due_date)
      );
      setTarifa({
        id: adesaoSelecionada.id_jaf_plus_tariff_type,
        name: adesaoSelecionada.type == null ? "" : adesaoSelecionada.type,
      });
      setPotencia({
        id: adesaoSelecionada.id_jaf_plus_tariff_power,
        name: adesaoSelecionada.power == null ? "" : adesaoSelecionada.power,
      });
      setCiclo({
        id: adesaoSelecionada.id_jaf_plus_tariff_cycle,
        name: adesaoSelecionada.cycle == null ? "" : adesaoSelecionada.cycle,
      });
      setEscalao({
        id: adesaoSelecionada.id_jaf_plus_tariff_level,
        name: adesaoSelecionada.level == null ? "" : adesaoSelecionada.level,
      });
      setTarifPlan({
        id: adesaoSelecionada.id_jaf_plus_tariff_plan,
        name: adesaoSelecionada.plan,
      });
      setTitCont({
        id: parseInt(adesaoSelecionada.jaf_plus_client_data_last_owner_flag),
        name:
          adesaoSelecionada.jaf_plus_client_data_last_owner_flag == "0"
            ? "Não"
            : "Sim",
      });
      setNewCon({
        id: parseInt(
          adesaoSelecionada.jaf_plus_client_data_new_connection_flag
        ),
        name:
          adesaoSelecionada.jaf_plus_client_data_new_connection_flag == "0"
            ? "Não"
            : "Sim",
      });
      setFornEne({
        id: parseInt(adesaoSelecionada.jaf_plus_client_data_power_supply_flag),
        name:
          adesaoSelecionada.jaf_plus_client_data_power_supply_flag == "0"
            ? "Não"
            : "Sim",
      });
      setInspGas({
        id: parseInt(
          adesaoSelecionada.jaf_plus_client_data_gas_inspection_flag
        ),
        name:
          adesaoSelecionada.jaf_plus_client_data_gas_inspection_flag == "0"
            ? "Não"
            : "Sim",
      });
      setFornGas({
        id: parseInt(adesaoSelecionada.jaf_plus_client_data_gas_supply_flag),
        name:
          adesaoSelecionada.jaf_plus_client_data_gas_supply_flag == "0"
            ? "Não"
            : "Sim",
      });
      setEstadoAde({
        id: adesaoSelecionada.estadoId,
        name: adesaoSelecionada.estadoNome,
      });
    }
  }, [adesaoSelecionada]);

  // ############################################################## //
  // ######################  Filtered arrays ###################### //
  // ############################################################## //

  const filteredTarifas = tiposTarifas.filter((t) => t.plan == tarifPlan.id);
  const filteredPotencias = potencias.filter((p) => p.type == tarifa.id);
  const filteredCiclos = ciclos.filter((c) => c.type == tarifPlan.id);
  const filteredEscaloes = escaloes.filter((e) => e.plan == tarifPlan.id);
  const filteredStatus = status.filter(
    (s) => s.servId == adesaoSelecionada.id_jaf_plus_service_type
  );

  // ############################################################## //
  // ###############  Handles Change and Submit  ################## //
  // ############################################################## //

  const handleAdesaoData = (date) => {
    setAdesaoData(date);
  };

  const handleAdesaoValiDoc = (date) => {
    setAdesaoValiDoc(date);
  };

  const handlePersonalInfoChange = (e) => {
    const { name, value } = e.target;
    setPersonalInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleCompanyInfoChange = (e) => {
    const { name, value } = e.target;
    setCompanyInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleDocsInfoChange = (e) => {
    const { name, value } = e.target;
    setDocsInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddressInfoChange = (e) => {
    const { name, value } = e.target;
    setAddressInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleOtherInfoChange = (e) => {
    const { name, value } = e.target;
    setOtherInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleUpacInfoChange = (e) => {
    const { name, value } = e.target;
    setUpacInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleUpacAddressInfoChange = (e) => {
    const { name, value } = e.target;
    setupacAddressInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const ydata = [
      {
        id_client: adesaoSelecionada.id_jaf_plus_client,
        clientTypeId: adesaoSelecionada.id_jaf_plus_client_type,
        serviceTypeId: adesaoSelecionada.id_jaf_plus_service_type,
        statusId: estadoAde.id,
        nome: personalInfo.nome,
        apelido: personalInfo.apelido,
        telemovel: personalInfo.telemovel,
        email: personalInfo.email,
        nif: docsInfo.nif,
        numero_documento: docsInfo.numero_documento,
        validade_documento: adesaoValiDoc
          ? moment(adesaoValiDoc).format("YYYY-MM-DD")
          : "0000-00-00",
        certidao_permanente: docsInfo.certidao_permanente,
        // preferencia_contacto: prefContacto.id,
        email_flag: prefContacto.id == 1 ? 1 : 0,
        phone_flag: prefContacto.id == 2 ? 1 : 0,
        mail_flag: prefContacto.id == 3 ? 1 : 0,
        iban: docsInfo.iban,
        tax_morada: taxMorada.id,
        morada: addressInfo.morada,
        cod_postal: addressInfo.cod_postal,
        localidade: addressInfo.localidade,
        concelho: addressInfo.concelho,
        distrito: addressInfo.distrito,
        plan: tarifPlan.id,
        tipotarifa: tarifa.id,
        potencia: potencia.id,
        ciclo: ciclo.id,
        escalao: escalao.id,
        aceito_condicoes_gerais: otherInfo.aceito_condicoes_gerais,
        observacoes: otherInfo.observacoes,
        data: moment(adesaoData).format("YYYY-MM-DD"),
        cpe: otherInfo.cpe,
        cui: otherInfo.cui,
        nipc: companyInfo.nipc,
        cae: companyInfo.cae,
        nome_empresa: companyInfo.nome_empresa,
        rep_legal: companyInfo.rep_legal,
        titular_contrato_anterior: titCon.id,
        nova_ligacao: newCon.id,
        fornecimento_energia: fornEne.id,
        inspecao_gas: inspGas.id,
        fornecimento_gas: fornGas.id,
        n_registo: upacInfo.n_registo,
        potencia_resp: upacInfo.potencia_resp,
        cpe_consumo: upacInfo.cpe_consumo,
        cpe_producao: upacInfo.cpe_producao,
        morada_upac: upacAddressInfo.morada_upac,
        cod_postal_upac: upacAddressInfo.cod_postal_upac,
        localidade_upac: upacAddressInfo.localidade_upac,
        concelho_upac: upacAddressInfo.concelho_upac,
        distrito_upac: upacAddressInfo.distrito_upac,
      },
    ];

    console.log(ydata);

    const errorMessage = {
      isOpen: true,
      message: "Ocorreu um erro!",
      type: "error",
    };
    const message = {
      isOpen: true,
      message: "Adesão editada com sucesso!!",
      type: "success",
    };

    dispatch(
      EditPlusAdesao(ydata, adesaoSelecionada.id_jaf_plus_client, message)
    )
      .then(() => {
        toggleDrawer("right", false);
        dispatch(ShowNotification(message));
        fetchAdesoes(servico.id, estado.id, date); // Ensure fetchAdesoes is called here
      })
      .catch((error) => {
        console.error("Error updating adesao:", error); // Handle error
      });
  };

  return (
    <div
      className={classes.list}
      style={
        dimensions.width <= 1100
          ? { width: "60vw" }
          : { width: `${drawerWidth}` }
      }
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          m: 5,
        }}
      >
        <Box
          sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={() => toggleDrawer("right", false)}
        >
          <div>
            <FeatherIcon
              icon="chevron-left"
              size={20}
              style={{ strokeWidth: "1.5" }}
            />
          </div>
          <div className="text-underline-close-drawer">Adesões</div>
        </Box>
        {/* <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            className={classes.btnFiltrar}
            onClick={handleSubmit}
          >
            Guardar
          </Button>
        </Box> */}
      </Box>
      <Card
        sx={{
          m: 5,
          p: 2,
          boxShadow: "0px 10px 15px lightgrey",
        }}
      >
        <Box
          sx={{
            my: 2,
            mx: 1,
          }}
        >
          <h4
            style={{
              fontSize: "22px",
              width: "100%",
              marginBottom: "0px",
            }}
            className="text-underline"
          >
            {adesaoSelecionada.id_jaf_plus_service_type == 1
              ? "Dados Adesão"
              : "Dados Compra de Excedentes"}
          </h4>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Estado
            </Typography>
            <Autocomplete
              fullWidth
              clearText="Limpar"
              openText="Abrir"
              closeText="Fechar"
              noOptionsText="Sem dados"
              options={filteredStatus || []}
              value={estadoAde}
              getOptionLabel={(option) => `${option.name}` || ""}
              isOptionEqualToValue={(option, value) => {
                // if (!value.id) return true;
                return value.id === option.id;
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" variant="outlined" />
              )}
              onChange={(_, value, reason) => {
                if (reason === "clear") {
                  setEstadoAde(null);
                  return;
                } else {
                  setEstadoAde(value);
                  return value;
                }
              }}
            />
          </Box>
          <Box
            sx={{
              m: 1,
              width: "48%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ width: "48%" }}>
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Data de submissão
              </Typography>
              <TextField
                disabled
                size="small"
                variant="outlined"
                value={adesaoData}
                style={{ width: "100%" }}
                autoComplete="off"
              />
            </Box>
            <Box sx={{ width: "48%" }}>
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Data da última atualização
              </Typography>
              <TextField
                disabled
                size="small"
                variant="outlined"
                value={adesaoLastUpdateData}
                style={{ width: "100%" }}
                autoComplete="off"
              />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 2,
            mx: 1,
          }}
        >
          <Typography
            style={{
              fontWeight: "bold",
              marginBottom: "10px",
              fontSize: "20px",
            }}
          >
            Dados Pessoais
          </Typography>
        </Box>
        {adesaoSelecionada &&
          adesaoSelecionada.id_jaf_plus_client_type == 1 && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ m: 1, width: "48%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Nome
                </Typography>
                <TextField
                  name="nome"
                  size="small"
                  variant="outlined"
                  value={personalInfo.nome}
                  onChange={handlePersonalInfoChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>
              <Box sx={{ m: 1, width: "48%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Apelido
                </Typography>
                <TextField
                  name="apelido"
                  size="small"
                  variant="outlined"
                  value={personalInfo.apelido}
                  onChange={handlePersonalInfoChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>
            </Box>
          )}
        {adesaoSelecionada &&
          adesaoSelecionada.id_jaf_plus_client_type == 2 && (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ m: 1, width: "48%" }}>
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    Nome da empresa
                  </Typography>
                  <TextField
                    name="nome_empresa"
                    size="small"
                    variant="outlined"
                    value={companyInfo.nome_empresa}
                    onChange={handleCompanyInfoChange}
                    style={{ width: "100%" }}
                    autoComplete="off"
                  />
                </Box>
                <Box sx={{ m: 1, width: "48%" }}>
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    Certidão Permanente
                  </Typography>
                  <TextField
                    name="certidao_permanente"
                    size="small"
                    variant="outlined"
                    value={docsInfo.certidao_permanente}
                    onChange={handleDocsInfoChange}
                    style={{ width: "100%" }}
                    autoComplete="off"
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ m: 1, width: "48%" }}>
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    NIPC
                  </Typography>
                  <TextField
                    name="nipc"
                    size="small"
                    variant="outlined"
                    value={companyInfo.nipc}
                    onChange={handleCompanyInfoChange}
                    style={{ width: "100%" }}
                    autoComplete="off"
                  />
                </Box>
                <Box sx={{ m: 1, width: "48%" }}>
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    CAE
                  </Typography>
                  <TextField
                    name="cae"
                    size="small"
                    variant="outlined"
                    value={companyInfo.cae}
                    onChange={handleCompanyInfoChange}
                    style={{ width: "100%" }}
                    autoComplete="off"
                  />
                </Box>
              </Box>
              <Box sx={{ m: 1, width: "48%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Representante Legal
                </Typography>
                <TextField
                  name="rep_legal"
                  size="small"
                  variant="outlined"
                  value={companyInfo.rep_legal}
                  onChange={handleCompanyInfoChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>
            </>
          )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Telemóvel
            </Typography>
            <TextField
              name="telemovel"
              size="small"
              variant="outlined"
              value={personalInfo.telemovel}
              onChange={handlePersonalInfoChange}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              NIF
            </Typography>
            <TextField
              name="nif"
              size="small"
              variant="outlined"
              value={docsInfo.nif}
              onChange={handleDocsInfoChange}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Nº Doc. Identificação
            </Typography>
            <TextField
              name="numero_documento"
              size="small"
              variant="outlined"
              value={docsInfo.numero_documento}
              onChange={handleDocsInfoChange}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
          <Box sx={{ m: 1, width: "48%" }}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              locale={ptLocale}
            >
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Validade Doc. Id.
              </Typography>
              <Box sx={{ display: "flex" }}>
                <DatePicker
                  margin="normal"
                  id="date-picker-dialog"
                  format="dd/MM/yyyy"
                  okLabel="ok"
                  cancelLabel="cancelar"
                  invalidDateMessage="Data Inválida"
                  value={adesaoValiDoc}
                  onChange={handleAdesaoValiDoc}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "dd/mm/aaaa",
                      }}
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                      }}
                      sx={{ width: "200px" }}
                      variant="outlined"
                      size="small"
                    />
                  )}
                />
              </Box>
            </LocalizationProvider>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Email
            </Typography>
            <TextField
              name="email"
              size="small"
              variant="outlined"
              value={personalInfo.email}
              onChange={handlePersonalInfoChange}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              IBAN
            </Typography>
            <TextField
              name="iban"
              size="small"
              variant="outlined"
              value={docsInfo.iban}
              onChange={handleDocsInfoChange}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {/* <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Tarifa Social
            </Typography>
            <TextField
              name="tarifaSocial"
              size="small"
              variant="outlined"
              value={adesao.tarifaSocial}
              onChange={handleChange}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box> */}
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Titular Contrato Anterior
            </Typography>
            <Autocomplete
              fullWidth
              clearText="Limpar"
              openText="Abrir"
              closeText="Fechar"
              noOptionsText="Sem dados"
              options={YesNo || []}
              value={titCon}
              getOptionLabel={(option) => `${option.name}` || ""}
              isOptionEqualToValue={(option, value) => {
                // if (!value.id) return true;
                return value.id === option.id;
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" variant="outlined" />
              )}
              onChange={(_, value, reason) => {
                if (reason === "clear") {
                  setTitCont(null);
                  return;
                } else {
                  setTitCont(value);
                  return value;
                }
              }}
            />
          </Box>
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Preferência de contacto
            </Typography>
            <Autocomplete
              fullWidth
              clearText="Limpar"
              openText="Abrir"
              closeText="Fechar"
              noOptionsText="Sem dados"
              options={prefContactos || []}
              value={prefContacto}
              getOptionLabel={(option) => `${option.name}` || ""}
              isOptionEqualToValue={(option, value) => {
                // if (!value.id) return true;
                return value.id === option.id;
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" variant="outlined" />
              )}
              onChange={(_, value, reason) => {
                if (reason === "clear") {
                  setPrefContacto(null);
                  return;
                } else {
                  setPrefContacto(value);
                  return value;
                }
              }}
            />
          </Box>
        </Box>
        {adesaoSelecionada &&
          adesaoSelecionada.id_jaf_plus_service_type == 2 && (
            <Box sx={{ m: 1, width: "48%" }}>
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                A morada fiscal é diferente do CPE
              </Typography>
              <Autocomplete
                fullWidth
                clearText="Limpar"
                openText="Abrir"
                closeText="Fechar"
                noOptionsText="Sem dados"
                options={YesNo || []}
                value={taxMorada}
                getOptionLabel={(option) => `${option.name}` || ""}
                isOptionEqualToValue={(option, value) => {
                  // if (!value.id) return true;
                  return value.id === option.id;
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" variant="outlined" />
                )}
                onChange={(_, value, reason) => {
                  if (reason === "clear") {
                    setTaxMorada(null);
                    return;
                  } else {
                    setTaxMorada(value);
                    return value;
                  }
                }}
              />
            </Box>
          )}
        {((adesaoSelecionada &&
          adesaoSelecionada.id_jaf_plus_service_type == 1) ||
          taxMorada.id == 1) && (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ m: 1, width: "48%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Morada
                </Typography>
                <TextField
                  name="morada"
                  size="small"
                  variant="outlined"
                  value={addressInfo.morada}
                  onChange={handleAddressInfoChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>

              {(tarifPlan.id == 3 || tarifPlan.id == 6) && (
                <Box sx={{ m: 1, width: "48%" }}>
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    CPE
                  </Typography>
                  <TextField
                    name="cpe"
                    size="small"
                    variant="outlined"
                    value={otherInfo.cpe}
                    onChange={handleOtherInfoChange}
                    style={{ width: "100%" }}
                    autoComplete="off"
                  />
                </Box>
              )}
              {(tarifPlan.id == 5 || tarifPlan.id == 7) && (
                <Box sx={{ m: 1, width: "48%" }}>
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    CUI
                  </Typography>
                  <TextField
                    name="cui"
                    size="small"
                    variant="outlined"
                    value={otherInfo.cui}
                    onChange={handleOtherInfoChange}
                    style={{ width: "100%" }}
                    autoComplete="off"
                  />
                </Box>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ m: 1, width: "48%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Cód-Postal
                </Typography>
                <TextField
                  name="cod_postal"
                  size="small"
                  variant="outlined"
                  value={addressInfo.cod_postal}
                  onChange={handleAddressInfoChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>
              <Box sx={{ m: 1, width: "48%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Localidade
                </Typography>
                <TextField
                  name="localidade"
                  size="small"
                  variant="outlined"
                  value={addressInfo.localidade}
                  onChange={handleAddressInfoChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ m: 1, width: "48%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Concelho
                </Typography>
                <TextField
                  name="concelho"
                  size="small"
                  variant="outlined"
                  value={addressInfo.concelho}
                  onChange={handleAddressInfoChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>
              <Box sx={{ m: 1, width: "48%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Distrito
                </Typography>
                <TextField
                  name="distrito"
                  size="small"
                  variant="outlined"
                  value={addressInfo.distrito}
                  onChange={handleAddressInfoChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>
            </Box>
          </>
        )}

        {(tarifPlan.id == 3 || tarifPlan.id == 6) && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ m: 1, width: "48%" }}>
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Nova Ligação
              </Typography>
              <Autocomplete
                fullWidth
                clearText="Limpar"
                openText="Abrir"
                closeText="Fechar"
                noOptionsText="Sem dados"
                options={YesNo || []}
                value={newCon}
                getOptionLabel={(option) => `${option.name}` || ""}
                isOptionEqualToValue={(option, value) => {
                  // if (!value.id) return true;
                  return value.id === option.id;
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" variant="outlined" />
                )}
                onChange={(_, value, reason) => {
                  if (reason === "clear") {
                    setNewCon(null);
                    return;
                  } else {
                    setNewCon(value);
                    return value;
                  }
                }}
              />
            </Box>
            <Box sx={{ m: 1, width: "48%" }}>
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Fornecimento de Energia
              </Typography>
              <Autocomplete
                fullWidth
                clearText="Limpar"
                openText="Abrir"
                closeText="Fechar"
                noOptionsText="Sem dados"
                options={YesNo || []}
                value={fornEne}
                getOptionLabel={(option) => `${option.name}` || ""}
                isOptionEqualToValue={(option, value) => {
                  // if (!value.id) return true;
                  return value.id === option.id;
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" variant="outlined" />
                )}
                onChange={(_, value, reason) => {
                  if (reason === "clear") {
                    setFornEne(null);
                    return;
                  } else {
                    setFornEne(value);
                    return value;
                  }
                }}
              />
            </Box>
          </Box>
        )}
        {(tarifPlan.id == 5 || tarifPlan.id == 7) && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ m: 1, width: "48%" }}>
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Inspeção Gás
              </Typography>
              <Autocomplete
                fullWidth
                clearText="Limpar"
                openText="Abrir"
                closeText="Fechar"
                noOptionsText="Sem dados"
                options={YesNo || []}
                value={inspGas}
                getOptionLabel={(option) => `${option.name}` || ""}
                isOptionEqualToValue={(option, value) => {
                  // if (!value.id) return true;
                  return value.id === option.id;
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" variant="outlined" />
                )}
                onChange={(_, value, reason) => {
                  if (reason === "clear") {
                    setInspGas(null);
                    return;
                  } else {
                    setInspGas(value);
                    return value;
                  }
                }}
              />
            </Box>
            <Box sx={{ m: 1, width: "48%" }}>
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Fornecimento Gás
              </Typography>
              <Autocomplete
                fullWidth
                clearText="Limpar"
                openText="Abrir"
                closeText="Fechar"
                noOptionsText="Sem dados"
                options={YesNo || []}
                value={fornGas}
                getOptionLabel={(option) => `${option.name}` || ""}
                isOptionEqualToValue={(option, value) => {
                  // if (!value.id) return true;
                  return value.id === option.id;
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" variant="outlined" />
                )}
                onChange={(_, value, reason) => {
                  if (reason === "clear") {
                    setFornGas(null);
                    return;
                  } else {
                    setFornGas(value);
                    return value;
                  }
                }}
              />
            </Box>
          </Box>
        )}
        {adesaoSelecionada &&
          adesaoSelecionada.id_jaf_plus_service_type == 2 && (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 2,
                  mx: 1,
                }}
              >
                <Typography
                  style={{
                    fontWeight: "bold",
                    marginBottom: "10px",
                    fontSize: "20px",
                  }}
                >
                  Dados UPAC
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ m: 1, width: "48%" }}>
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    Número de registo
                  </Typography>
                  <TextField
                    name="n_registo"
                    size="small"
                    variant="outlined"
                    value={upacInfo.n_registo}
                    onChange={handleUpacInfoChange}
                    style={{ width: "100%" }}
                    autoComplete="off"
                  />
                </Box>
                <Box sx={{ m: 1, width: "48%" }}>
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    Potência de injeção na RESP (kVA)
                  </Typography>
                  <TextField
                    name="potencia_resp"
                    size="small"
                    variant="outlined"
                    value={upacInfo.potencia_resp}
                    onChange={handleUpacInfoChange}
                    style={{ width: "100%" }}
                    autoComplete="off"
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ m: 1, width: "48%" }}>
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    CPE Consumo
                  </Typography>
                  <TextField
                    name="cpe_consumo"
                    size="small"
                    variant="outlined"
                    value={upacInfo.cpe_consumo}
                    onChange={handleUpacInfoChange}
                    style={{ width: "100%" }}
                    autoComplete="off"
                  />
                </Box>
                <Box sx={{ m: 1, width: "48%" }}>
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    CPE Produção
                  </Typography>
                  <TextField
                    name="cpe_producao"
                    size="small"
                    variant="outlined"
                    value={upacInfo.cpe_producao}
                    onChange={handleUpacInfoChange}
                    style={{ width: "100%" }}
                    autoComplete="off"
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ m: 1, width: "48%" }}>
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    Morada
                  </Typography>
                  <TextField
                    name="morada_upac"
                    size="small"
                    variant="outlined"
                    value={upacAddressInfo.morada_upac}
                    onChange={handleUpacAddressInfoChange}
                    style={{ width: "100%" }}
                    autoComplete="off"
                  />
                </Box>
                <Box sx={{ m: 1, width: "48%" }}>
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    Cód-Postal
                  </Typography>
                  <TextField
                    name="cod_postal_upac"
                    size="small"
                    variant="outlined"
                    value={upacAddressInfo.cod_postal_upac}
                    onChange={handleUpacAddressInfoChange}
                    style={{ width: "100%" }}
                    autoComplete="off"
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ m: 1, width: "48%" }}>
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    Localidade
                  </Typography>
                  <TextField
                    name="localidade_upac"
                    size="small"
                    variant="outlined"
                    value={upacAddressInfo.localidade_upac}
                    onChange={handleUpacAddressInfoChange}
                    style={{ width: "100%" }}
                    autoComplete="off"
                  />
                </Box>
                <Box sx={{ m: 1, width: "48%" }}>
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    Concelho
                  </Typography>
                  <TextField
                    name="concelho_upac"
                    size="small"
                    variant="outlined"
                    value={upacAddressInfo.concelho_upac}
                    onChange={handleUpacAddressInfoChange}
                    style={{ width: "100%" }}
                    autoComplete="off"
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ m: 1, width: "48%" }}>
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    Distrito
                  </Typography>
                  <TextField
                    name="distrito_upac"
                    size="small"
                    variant="outlined"
                    value={upacAddressInfo.distrito_upac}
                    onChange={handleUpacAddressInfoChange}
                    style={{ width: "100%" }}
                    autoComplete="off"
                  />
                </Box>
              </Box>
            </>
          )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            m: 1,
            mt: 2,
          }}
        >
          <Typography
            style={{
              fontWeight: "bold",
              marginBottom: "10px",
              fontSize: "20px",
            }}
          >
            Plano
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Serviço
            </Typography>
            <Autocomplete
              fullWidth
              clearText="Limpar"
              openText="Abrir"
              closeText="Fechar"
              noOptionsText="Sem dados"
              options={planos || []}
              value={tarifPlan}
              getOptionLabel={(option) => `${option.name}` || ""}
              isOptionEqualToValue={(option, value) => {
                // if (!value.id) return true;
                return value.id === option.id;
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" variant="outlined" />
              )}
              onChange={(_, value, reason) => {
                if (reason === "clear") {
                  setTarifPlan(null);
                  return;
                } else {
                  setTarifPlan(value);
                  return value;
                }
              }}
            />
          </Box>
        </Box>
        {tarifPlan.id == 3 && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ m: 1, width: "48%" }}>
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Tipo de Tarifa
              </Typography>
              <Autocomplete
                fullWidth
                clearText="Limpar"
                openText="Abrir"
                closeText="Fechar"
                noOptionsText="Sem dados"
                options={filteredTarifas || []}
                value={tarifa}
                getOptionLabel={(option) => `${option.name}` || ""}
                isOptionEqualToValue={(option, value) => {
                  // if (!value.id) return true;
                  return value.id === option.id;
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" variant="outlined" />
                )}
                onChange={(_, value, reason) => {
                  if (reason === "clear") {
                    setTarifa(null);
                    return;
                  } else {
                    setTarifa(value);
                    return value;
                  }
                }}
              />
            </Box>
            <Box sx={{ m: 1, width: "48%" }}>
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Potência
              </Typography>
              <Autocomplete
                fullWidth
                clearText="Limpar"
                openText="Abrir"
                closeText="Fechar"
                noOptionsText="Sem dados"
                options={filteredPotencias || []}
                value={potencia}
                getOptionLabel={(option) => `${option.name}` || ""}
                isOptionEqualToValue={(option, value) => {
                  // if (!value.id) return true;
                  return value.id === option.id;
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" variant="outlined" />
                )}
                onChange={(_, value, reason) => {
                  if (reason === "clear") {
                    setPotencia(null);
                    return;
                  } else {
                    setPotencia(value);
                    return value;
                  }
                }}
              />
            </Box>
          </Box>
        )}
        {(tarifPlan.id == 5 || tarifPlan.id == 7) && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {tarifPlan.id == 5 && (
              <Box sx={{ m: 1, width: "48%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Ciclo
                </Typography>
                <Autocomplete
                  fullWidth
                  clearText="Limpar"
                  openText="Abrir"
                  closeText="Fechar"
                  noOptionsText="Sem dados"
                  options={filteredCiclos || []}
                  value={ciclo}
                  getOptionLabel={(option) => `${option.name}` || ""}
                  isOptionEqualToValue={(option, value) => {
                    // if (!value.id) return true;
                    return value.id === option.id;
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" variant="outlined" />
                  )}
                  onChange={(_, value, reason) => {
                    if (reason === "clear") {
                      setCiclo(null);
                      return;
                    } else {
                      setCiclo(value);
                      return value;
                    }
                  }}
                />
              </Box>
            )}
            <Box sx={{ m: 1, width: "48%" }}>
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Escalão
              </Typography>
              <Autocomplete
                fullWidth
                clearText="Limpar"
                openText="Abrir"
                closeText="Fechar"
                noOptionsText="Sem dados"
                options={filteredEscaloes || []}
                value={escalao}
                getOptionLabel={(option) => `${option.name}` || ""}
                isOptionEqualToValue={(option, value) => {
                  // if (!value.id) return true;
                  return value.id === option.id;
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" variant="outlined" />
                )}
                onChange={(_, value, reason) => {
                  if (reason === "clear") {
                    setEscalao(null);
                    return;
                  } else {
                    setEscalao(value);
                    return value;
                  }
                }}
              />
            </Box>
          </Box>
        )}
        <Box sx={{ m: 1 }}>
          <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
            Observações
          </Typography>
          <TextField
            name="observacoes"
            size="small"
            variant="outlined"
            value={otherInfo.observacoes}
            onChange={handleOtherInfoChange}
            style={{ width: "100%" }}
            autoComplete="off"
            InputProps={{
              inputComponent: TextareaAutosize,
              inputProps: {
                minRows: 3,
                style: { width: "100%" },
              },
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 2,
            mx: 1,
          }}
        >
          <Typography
            style={{
              fontWeight: "bold",
              fontSize: "20px",
            }}
          >
            Documentos
          </Typography>
        </Box>
        <Box sx={{ display: "flex" }}>
          {documentos.map((d) => (
            <Box sx={{ m: 1, display: "flex", alignItems: "center" }}>
              <Typography>{d.name}</Typography>
              <Button
                sx={{
                  height: "40px",
                  borderRadius: "5px",
                  mx: 2,
                }}
                className={classes.btnUpload}
                onClick={() => {
                  // Define the file URL stored in your system
                  const fileUrl = `https://jafplus.pt/wp-content${d.path}`; // Replace with your actual file URL

                  // Create a temporary anchor element
                  const link = document.createElement("a");
                  link.href = fileUrl;

                  // Set the target attribute to open in a new window
                  link.setAttribute("target", "_blank");

                  // Append the link to the document body
                  document.body.appendChild(link);

                  // Programmatically click the link to open the file in a new window/tab
                  link.click();

                  // Remove the link after the operation
                  document.body.removeChild(link);
                }}
              >
                <FeatherIcon icon="download" size={20} />
              </Button>
            </Box>
          ))}
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end", m: 1 }}>
          <Button
            variant="contained"
            className={classes.btnFiltrar}
            onClick={handleSubmit}
          >
            Guardar
          </Button>
        </Box>
        {/* <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Fatura Eletricidade
            </Typography>
            <TextField
              name="ynb_adesao_fatura_eleticidade_file"
              size="small"
              variant="outlined"
              value={adesao.ynb_adesao_fatura_eleticidade_file}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Comprovativo IBAN
            </Typography>
            <TextField
              name="ynb_adesao_comprovativo_iban_file"
              size="small"
              variant="outlined"
              value={adesao.ynb_adesao_comprovativo_iban_file}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box> */}
      </Card>
    </div>
  );
};

export default Form;
